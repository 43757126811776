<template>
  <div class="customer-account-notes">
    <div class="customer-account-notes--content">
      <div>
        <CRTextArea
          id="customer-accounts-notes`"
          v-model="notes"
          label="Notes"
          placeholder="Add a comment"
          :rows="4"
          auto-grow
        />
      </div>
      <div class="customer-account-notes--btn-spacer"></div>
      <v-btn
        :id="`quote-form-trip-set-trip-notes-button`"
        class="customer-account-notes--action-btn"
        color="primary"
        style="margin: 0; border-radius: 0"
        @click="submitNotesUpdate"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import customerAccounts from '@/services/customerAccounts'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    customerAccountId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      notes: '',
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    customerAccountId: {
      handler() {
        this.loadData()
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      if (this.customerAccountId) {
        try {
          const customerAccountResponse = await customerAccounts.getCustomerAccount(
            this.customerAccountId
          )
          const customerAccount = customerAccountResponse.data

          this.notes = customerAccount.notes
        } catch (e) {
          console.error(e)
        }
      }
    },
    async submitNotesUpdate() {
      const payload = {
        notes: this.notes,
      }

      this.loading = true
      await customerAccounts.modifyCustomerAccount({
        id: this.customerAccountId,
        payload,
      })
      this.loading = false

      this.$store.dispatch('app/closeDialog')
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-account-notes {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
    z-index: 100;
    right: 0;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
